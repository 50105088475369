import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageError as TSFecPageError } from '@twigeducation/ts-fe-components';
import { useTranslation } from '../../../hooks/useTranslation';

const StyledPageError = styled(TSFecPageError)`
    flex: 1;
    justify-content: center;
    margin-bottom: 1rem;
`;

const PageError = ({ message, title, ...props }) => {
    const { t } = useTranslation();
    return (
        <StyledPageError
            title={title || t('error-messages.something_went_wrong', 'Sorry, something went wrong')}
            body={message}
            {...props}
        />
    );
};

PageError.defaultProps = {
    title: undefined,
};

PageError.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
};

export default PageError;
