import styled from 'styled-components';

export const Container = styled.div`
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
`;

export const Header = styled.div`
    margin-bottom: 1rem;
`;

export const Summary = styled.p`
    color: ${props => props.theme.primaryColor};
    width: 80%;
    @media screen and (max-width: ${props => props.theme.grid.breakpoints[0]}) {
        width: 100%;
    }
`;
